'use client'
import { Bookmark, Ellipsis, Flag, MessageCircleMore, } from "lucide-react"
import Image from "next/image"
import { profile } from "../../../../../public/images"
import { AiOutlineLike } from "react-icons/ai";
import { useRouter } from "next/navigation";
import { useState } from "react";
import CommentModal from "../explore/CommentModal";

const ExploreArticleCard = ({article})=>{
  const router = useRouter()
  const [isOpen,setIsOpen] = useState(false)
  const [report ,setReport] = useState(false)
    const truncateText = (text, limit) => {
        const words = text.split(' ');
        if (words.length > limit) {
          return words.slice(0, limit).join(' ') + '...';
        }
        return text;
      };
  return (
    <div className="flex md:flex-row hover:bg-gray-100 rounded-md p-1 flex-col-reverse gap-2 h-fit border-b pb-3 border-[#E4E4E7]  w-full">
      
        <Image src={article?.coverImage} alt="profile"  className="rounded-sm md:w-[200px] w-full h-[170px] md:h-[130px] object-top object-fill" width={200} height={170}/>
      <div className="flex flex-col w-full gap-1 p-2">
        <div className="flex gap-2 items-center">
        <span 
        // onClick={()=>router.push('/dashboard/author')}
         className='flex gap-2 cursor-pointer'>
          {
            article?.author?.profileImage  ?<Image src={article?.author?.profileImage} alt="" width={30} height={30}/>:
            <span className="w-[30px] h-[30px] rounded-full bg-gray-600 text-white flex justify-center items-center">
              A
            </span>

          }
                <p className='text-[16px] text-[#8641BD] font-[500]'>{article?.author?.name || "Author"} </p>
        </span>
        <span onClick={()=>router.push('/dashboard/newsletter')} className="text-[13px] hover:text-[#8641BD] cursor-pointer font-[700]">في نشرة رسالة السبت</span>
        </div>
        {/* title */}
        <div 
        onClick={()=>router.push(`/dashboard/view-article/${article?._id}`)}
         className=" cursor-pointer w-full">
          <p className="text-[#18171D] text-[18px] xl:text-[20px] font-[600] ">
            {truncateText(article?.title, 12)}
          </p>
        </div>
        {/* content */}
        <p className="text-[#27262B] xl:text-[14px] text-[12px] font-[500]">
          {truncateText(article?.subTitle, 20)}
        </p>
        {/*  */}
        <div className="flex relative items-center w-full justify-between">
            <span className="flex gap-3">
                <p className="text-[13px] font-[500]">12 أغسطس</p>
                <span className="flex gap-2 text-[#4D5861] text-[11px] font-[500]">
                    <span onClick={()=>setIsOpen(!isOpen)} className="flex gap-1 hover:text-black cursor-pointer">
                        <p>56</p>
                        <MessageCircleMore size={20} />
                    </span>
                    <span className="flex gap-1 cursor-pointer hover:text-black">
                        <p>3.1</p>
                        <AiOutlineLike className="" size={20} />
                    </span>          
                </span>
            </span>
            <span className="flex text-[#4D5861] gap-2">
                        <Bookmark className="cursor-pointer hover:text-black" size={20} />
                        <Ellipsis onClick={()=>setReport(!report)} className="cursor-pointer hover:text-black"  size={20} />
            </span>
            {report && <span className='bg-white rounded-md text-black flex items-center gap-1 border p-2 absolute bottom-[-45px] w-fit -left-2 cursor-pointer'>
                <Flag width={15} height={15} className='cursor-pointer'/> 
                الإبلاغ عن المنشور</span>}
        </div>
      </div>
      {isOpen && <CommentModal isOpen={isOpen} onClose={()=>setIsOpen(false)}/>}
    </div>
  )
}

export default ExploreArticleCard
import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from '../baseQuery';

export const articlesApi = createApi({
  reducerPath: 'articlesApi',
  baseQuery: baseQuery,
  tagTypes: ['authorArticles', 'allArticles'],
  endpoints: (builder) => ({
    createArticle: builder.mutation({
      query: (articleData) => ({
        url: '/articles',
        method: 'POST',
        body: articleData,
      }),
    }),

    getAllArticles: builder.query({
      query: () => '/articles',
      providesTags: ['allArticles'],
    }),
    getAuthorArticles: builder.query({
      query: () => '/articles/author',
      providesTags: ['authorArticles'],
    }),

    getArticleById: builder.query({
      query: (id) => `/articles/${id}`,
    }),

    updateArticle: builder.mutation({
      query: ({ id, ...updatedData }) => ({
        url: `/articles/${id}`,
        method: 'PUT',
        body: updatedData,
      }),
      invalidatesTags: ['authorArticles', 'allArticles'],
    }),

    deleteArticle: builder.mutation({
      query: (id) => ({
        url: `/articles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['authorArticles', 'allArticles'],
    }),
  }),
});

export const {
  useCreateArticleMutation,
  useGetAllArticlesQuery,
  useGetArticleByIdQuery,
  useUpdateArticleMutation,
  useDeleteArticleMutation,
  useGetAuthorArticlesQuery,
} = articlesApi;

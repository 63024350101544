'use client';

import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import {
  ChartPie,
  ChevronDown,
  Lightbulb,
  Newspaper,
  PencilRuler,
  Search,
  SquarePen,
} from 'lucide-react';

import { logo, profile } from '../../../../../public/images';
import CreateIdeaModal from './CreateModal';
import Dropdown from './Dropdown';
import NavItem from './NavItem';
import Notification from './Notification';
import { Button } from '@/components/ui/button';

const HomeNavbar = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openIdeaModal, setOpenIdeaModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const router = useRouter();
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="flex  items-center justify-between border-b-2 border-[#E4E4E7] py-3 px-4 md:px-8 lg:px-12 w-full">
      {/*  */}
      <div className="flex gap-2 lg:gap-4 items-center justify-center">
        <Image
          src={logo}
          alt="logo"
          width={80}
          height={80}
          className="cursor-pointer"
          onClick={() => router.push('/dashboard/explore')}
        />
        <span className="w-1 bg-[#E4E4E7] h-12 rounded-full" />
        {/* <NavItem link={'/dashboard/analytics'} title="أرقامك" icon={<ChartPie width={15} height={15}/>}/>
        <NavItem link={'/dashboard/save-idea'} title={'أفكارك'} icon = {<Lightbulb width={15} height={15}/>}/>
        <NavItem link={'/dashboard/articles'} title="كتاباتك" icon={<Newspaper width={15} height={15}/>}/>
        <NavItem link={'/dashboard/workshop'} title="الورشة" icon={<PencilRuler width={18} height={18}/>}/> */}
      </div>
    <Button onClick={() => router.push('/signin')} className="bg-[#8641BD] text-white w-fit self-center">Sign In</Button>

      {/*  */}
      {/* <div className="flex gap-2 items-center justify-center">
        <Search
          width={20}
          height={20}
          onClick={() => router.push('/dashboard/explore/topics')}
          className="text-[#4D5861] hover:text-[#8641BD] sm:flex hidden cursor-pointer"
        />
        <span
          onClick={() => setOpenIdeaModal(true)}
          className="flex items-center justify-center text-[16px]  lg:text-[18px] font-[500] cursor-pointer text-opacity-50"
        >
          <p className="text-[18px] lg:text-[20px]">💡</p>
          <p className="text-[#7f7f7f] hover:text-[#8641BD]">دوّن فكرة</p>
        </span>
        {openIdeaModal && (
          <CreateIdeaModal
            isOpen={openIdeaModal}
            onClose={() => setOpenIdeaModal(false)}
          />
        )}
        <NavItem
          link={'/text-editor'}
          title="  اكتب"
          icon={<SquarePen width={20} height={15} />}
        />
        <Image
          src={profile}
          alt="profile"
          width={60}
          height={60}
          className="rounded-full"
        />
        <span ref={dropdownRef} className="relative">
          <ChevronDown
            width={20}
            onClick={() => setOpenDropdown(!openDropdown)}
            height={20}
            className="cursor-pointer"
          />
          {openDropdown && (
            <Dropdown
              setShowNotifications={setShowNotifications}
              setOpenDropdown={setOpenDropdown}
            />
          )}
        </span>
      </div> */}
      {/* {showNotifications && (
        <div className=" absolute z-50  top-20  left-5">
          {' '}
          <Notification setShowNotifications={setShowNotifications} />
        </div>
      )} */}
    </div>
  );
};

export default HomeNavbar;

const isProduction = process.env.MODE === "production";

const appConfig = {
  baseUrl: isProduction
    ? "http://13.233.107.178:5000"
    : "http://13.233.107.178:5000",
  apiPrefix: "api",
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/login",
  tourPath: "/",
  locale: "en",
};

export default appConfig;
